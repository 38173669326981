<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">Edit trace<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to types of personnel list"
              @click="$router.push({name: 'admin.traces.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div
                v-if="admin.roles.includes('super-admin')"
                class="card"
              >
                <div
                  v-b-toggle="`collapseResearcher`"
                  class="card-header"
                >
                  <h3 class="cart-title">
                    <a data-action="collapse">Researcher</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <b-collapse
                  :id="`collapseResearcher`"
                  visible
                >
                  <div class="card-content">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Search researcher</label>
                            <input
                              v-model="item.targetUser"
                              type="text"
                              class="form-control"
                              disabled
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Text</label>
                          <quill-editor v-model="item.note" />
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Date</label>
                          <date-picker
                            v-model="item.date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Source</label>
                          <input
                            v-model="item.noteable_name"
                            type="text"
                            class="form-control "
                            disabled
                          >
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Created by</label>
                          <input
                            v-model="item.created_by"
                            type="text"
                            class="form-control"
                            disabled
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      itemId: this.$route.params.id,
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      item: 'traces/item',
      admin: 'auth/admin',
    }),
  },
  async mounted() {
    if (this.itemId) {
      await this.$store.dispatch('traces/fetchId', this.itemId)
    }
  },
  methods: {
    async save() {
      this.sending = true
      try {
        if (this.itemId) {
          await this.$store.dispatch('traces/update', { id: this.itemId, data: this.item })
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The trace has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.traces.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
  },
}
</script>
